import { PopupService } from './popup.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Folitropina Delta';
  showAvisoLegal$!: Observable<any>;
  showAvisoFarmacovigilancia$!: Observable<any>;
  showPopupPolitica$!: Observable<any>;
  showPopupPoliticaUso$!: Observable<any>;
  showPopupPoliticaCookies$!: Observable<any>;
  unsupportedBrowser = false;


  constructor(
    private popupService: PopupService,
    private cookieService: CookieService,
    private $gaService: GoogleAnalyticsService,
    private router: Router) {
    this.showAvisoLegal$ = this.popupService.showPopUpAvisoLegal$;
    this.showAvisoFarmacovigilancia$ = this.popupService.showPopUpAvisoFarmacovigilancia$;
    this.showPopupPolitica$ = this.popupService.showPopUpPoliticaPrivacidad$;
    this.showPopupPoliticaUso$ = this.popupService.showPopUpPoliticaUso$;
    this.showPopupPoliticaCookies$ = this.popupService.showPopUpPoliticaCookies$;

    this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {

        if ($.fn.ihavecookies.preference('analytics') === true) {
          this.$gaService.pageView( event.url, event.url);

        }
      }
    });

  }

  ngOnInit(): void {
    this.unsupportedBrowser = this.isIE();

    this.showAvisoFarmacovigilancia();

    $('body').ihavecookies({
      message: 'Ferring, S.A.U. (en adelante Ferring) utiliza en este sitio cookies y tecnologías similares (en adelante cookies) tanto propias como de terceros consistentes en ficheros que se instalan en el dispositivo o equipo desde el que la persona usuaria de este sitio navega y registran información de su navegación. Algunas cookies son necesarias para hacer accesible este sitio  web y sus contenidos y servicios. Otras cookies son utilizadas por Ferring y anunciantes, proveedores tecnológicos de publicidad y otros terceros para analizar y medir las audiencias y tráfico de este sitio, desarrollar y mostrar publicidad. La instalación de las cookies de los terceros antedichos implica la trasferencia internacional de datos a Estados Unidos. La instalación de estas cookies y las transferencias internacionales solo se llevarán a cabo con su consentimiento. Elija la opción que desea establecer mediante el panel aceptando todas las cookies para el sitio, rechazando todas o configurando de forma personalizada su uso. Para saber más, consulte nuestra política de cookies donde dispone de información más completa sobre las cookies, los tipos de terceros que pueden instalar cookies en su dispositivo o navegador cuando navega por este sitio web, las finalidades para las que se instalan las cookies y como desactivar o activar las cookies.',
      acceptBtnLabel: 'Aceptar cookies',
      declineBtnLabel: 'Rechazar cookies',
      advancedBtnLabel: 'Ajustes de cookies',
      moreInfoLabel: 'Política de cookies',
      cookieTypesTitle: 'Seleccione las cookies que desea aceptar',
      fixedCookieTypeLabel: 'Necesarias',
      title: 'Cookies y privacidad',
      cookieTypes: [
        {
          type: 'Analytics',
          value: 'analytics',
          description: 'Cookies relativas a las visitas del sitio, tipos de navegador, etc.'
        }
      ],
      onAccept: () => {
        if ( !$.fn.ihavecookies.preference('analytics')){

          this.cookieService.delete('_ga', '/', '.folitropinadelta.es');
          this.cookieService.delete('_gid', '/', '.folitropinadelta.es');
          this.cookieService.delete('_gid', '/', '.folitropinadelta.es');

        }else {

        }
      },
      onDecline: () => {
        if (!$.fn.ihavecookies.preference('analytics')){
          this.cookieService.delete('_ga', '/', '.folitropinadelta.es');
          this.cookieService.delete('_gid', '/', '.folitropinadelta.es');
          this.cookieService.delete('_gid', '/', '.folitropinadelta.es');
        }
      }
    });


  }



  showAvisoLegal(): void { this.popupService.showPopUpAvisoLegal(); }
  closeAvisoLegal(): void { this.popupService.closePopUpAvisoLegal(); }

  showAvisoFarmacovigilancia(): void { this.popupService.showPopUpAvisoFarmacovigilancia(); }
  closeAvisoFarmacovigilancia(): void { this.popupService.closePopUpAvisoFarmacovigilancia(); }

  showPoliticaPrivacidad(): void { this.popupService.showPopUpPoliticaPrivacidad(); }
  closePoliticaPrivacidad(): void { this.popupService.closePopUpPoliticaPrivacidad(); }

  showPoliticaUso(): void { this.popupService.showPopUpPoliticaUso(); }
  closePoliticaUso(): void { this.popupService.closePopUpPoliticaUso(); }

  showPoliticaCookies(): void { this.popupService.showPopUpPoliticaCookies(); }
  closePoliticaCookies(): void { this.popupService.closePopUpPoliticaCookies(); }


  isIE(): boolean {
    return /Trident\/|MSIE/.test(window.navigator.userAgent);
  }

}
